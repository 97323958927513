.vegas-content-scrollable {
  position: absolute;
}
.vegas-content {
  height: 100%;
  padding: 2.5rem 7rem !important;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xl) {
    padding: 2.5rem $container-spacing-x-lg !important;
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-md !important;
  }
}
