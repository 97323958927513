.section-title {
  &-tc,
  &-en {
    color: $primary;
  }
  &-en {
    font-size: 4.5rem;
    font-weight: $font-weight-bolder;
  }
  &-tc {
    font-size: 2.5rem;
    font-weight: $font-weight-bold;
  }
  @include media-breakpoint-down(xxl) {
    &-en {
      font-size: 3rem;
    }
    &-tc {
      font-size: 2rem;
    }
  }
  @include media-breakpoint-down(md) {
    &-en {
      font-size: 2rem;
    }
    &-tc {
      font-size: 1.5rem;
    }
  }
}
.business-card {
  width: 650px;
  height: 100%;
  position: relative;
  color: #fff;
  transform-style: preserve-3d;
  transition: 1s;

  &-title {
    font-size: 2.5rem;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    img {
      margin-right: 1rem;
    }
  }
  &-front,
  &-back {
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/business-card-bg.svg) left center/1209px 687px
      no-repeat no-repeat;
    border-radius: 1rem;
    padding: 2.3rem;
    display: flex;
    justify-content: center;

    flex-direction: column;
    &::after {
      content: "";
      display: block;
      width: 103px;
      height: 94px;
      background: url(../images/in.svg) center center/contain no-repeat;
      opacity: 0.35;
      right: 1.5rem;
      bottom: 1.5rem;
      position: absolute;
    }
  }
  &-front {
    align-items: center;
    font-size: $font-size-lg;
    img {
      width: 136px;
      height: auto;
      margin-bottom: 1rem;
    }
  }
  &-back {
    align-items: flex-start;
    transform: rotateY(180deg);
  }
  &.open {
    transform: rotateY(180deg);
  }
  @include media-breakpoint-down(xxl) {
    &-title {
      font-size: 2rem;
    }
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    height: 300px;
    &-front,
    &-back {
      padding: 1.5rem;
    }
    &-front {
      img {
        width: 50px;
      }
    }
    &-title {
      font-size: 1.2rem;
      img {
        width: 30px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
#banner,
#service-items,
#advantage {
  min-height: 100vh;
  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 56px);
  }
}
#banner {
  // background: url(../images/banner-bg.jpg) center center/cover no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2.5rem $container-spacing-x;
  &::before {
    content: "";
    background: url(../images/slogan-yun.svg) center center/contain no-repeat;
    width: 545px;
    height: 600px;
    position: absolute;
    right: $container-spacing-x;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
  }
  #logo {
    position: absolute;
    left: $container-spacing-x;
    top: 2.5rem;
  }
  .slogan {
    position: relative;
    color: $primary;
    font-size: 6rem;
    font-weight: $font-weight-bolder;
    font-style: italic;
    line-height: 1;
    &-tc {
      font-size: 1.5rem;
      color: #000;
      font-weight: $font-weight-bolder;
      margin-top: 1.5rem;
    }
  }
  @include media-breakpoint-down(xxl) {
    .slogan {
      font-size: 4rem;
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 2.5rem $container-spacing-x-lg;
    #logo {
      left: $container-spacing-x-lg;
    }
    &::before {
      right: $container-spacing-x-lg;
    }
  }
  @include media-breakpoint-down(lg) {
    #logo {
      width: 350px;
    }
    &::before {
      width: 300px;
    }
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-md;
    &::before {
      width: 150px;
      right: $container-spacing-x-md;
    }
    .slogan {
      font-size: 2rem;
      &-tc {
        font-size: 1.3rem;
        margin-top: 1rem;
      }
    }
    #logo {
      img {
        width: 250px;
      }
      left: $container-spacing-x-md;
      top: $container-spacing-y-md;
    }
  }
}
#about-us {
  height: 500px;
  background: #02090f url(../images/about-bg.jpg) right center/1024px 640px
    no-repeat;
  position: relative;
  padding: 2.5rem $container-spacing-x;
  .section-title {
    position: absolute;
    top: -55px;
    right: $container-spacing-x;
    &-tc {
      text-align: right;
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 2.5rem $container-spacing-x-lg;
    .section-title {
      top: -80px;
      right: $container-spacing-x-lg;
    }
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-md;
    padding-top: 4rem;
    height: auto;
    .section-title {
      right: $container-spacing-x-md;
      top: -45px;
      &-tc {
        margin-top: 1rem;
      }
    }
  }
}
#service-items {
  background: $secondary;
  padding-top: 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content min-content auto;
  .section-title {
    padding-left: $container-spacing-x;
    padding-right: 2rem;
    margin-bottom: 3rem;
  }
  .sub-slogan {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    width: calc(100% + 8rem);
    z-index: 2;
    &.digital {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      background: url(../images/digital.jpg) center center/110% no-repeat;
      border-top-right-radius: 1rem;
    }
    &.creativity {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
      background: url(../images/creativity.jpg) center center/110% no-repeat;
      border-bottom-right-radius: 1rem;
      margin-bottom: 4.5rem;
    }
    h4 {
      font-size: 3rem;
      font-style: italic;
      font-weight: 600;
      text-shadow: 0px 0 10px rgb(255 255 255 / 60%);
      .en {
        font-size: 2rem;
      }
    }
  }

  .service-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / span 1;
    grid-row: 1 / span 4;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      width: 100%;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      background: url(../images/service-bg.svg) left top/1851px 1052px no-repeat;
    }
    &::after {
      content: "SERVICE";
      position: absolute;
      writing-mode: tb-rl;
      right: 0;
      bottom: 4.5rem;
      font-size: $container-spacing-x;
      color: #e03734;
      opacity: 0.1;
      line-height: 1;
      transform: rotate(180deg);
      font-weight: $font-weight-bolder;
    }
    ul {
      @include ul-no-style;
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fff;
    }
    li:not(:last-child) {
      margin-bottom: 2.5rem;
    }
    h4 {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
    p {
      margin-bottom: 0;

      span {
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          background: #212529;
          display: inline-block;
          border-radius: 50%;
          vertical-align: middle;
          margin: 0 8px 0 9px;
          transform: translateY(-1px);
        }
      }
    }
  }
  @include media-breakpoint-down(xxl) {
    grid-template-columns: 1fr 2fr;
    .service-card {
      grid-template-columns: 1fr 3fr;
    }
    .sub-slogan {
      height: 300px;
      h4 {
        font-size: 2rem;
        .en {
          font-size: 1.5rem;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .section-title {
      padding-left: $container-spacing-x-lg;
    }
  }
  @include media-breakpoint-down(lg) {
    display: block;
    padding-top: 2.5rem;
    .section-title {
      margin-bottom: 2.5rem;
    }
    .sub-slogan {
      width: calc(100%);
      &.digital {
        border-top-right-radius: 0;
      }
      &.creativity {
        border-bottom-right-radius: 0;
        margin-bottom: 2.5rem;
      }
    }
    .service-card {
      margin-left: $container-spacing-x-lg;
      grid-template-columns: 1fr 6fr;
      &::after {
        font-size: 3rem;
        bottom: 2.5rem;
        line-height: 1.6;
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-y-md 0;
    .section-title {
      margin-bottom: $container-spacing-y-md;
      padding: 0 $container-spacing-x-md;
    }
    .service-card {
      margin-left: $container-spacing-x-md;
      ul {
        padding: $container-spacing-md;
      }
      li:not(:last-child) {
        margin-bottom: $container-spacing-y-md;
      }
    }
    .sub-slogan {
      height: 150px;
      h4 {
        font-size: 1.5rem;
        .en {
          font-size: 1rem;
        }
      }
    }
    .sub-slogan.creativity {
      margin-bottom: $container-spacing-x-md;
    }
  }
}
#advantage {
  background: $secondary;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2.5rem $container-spacing-x;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 200px;
    background: #fff;
    position: absolute;
    top: -4.5rem;
    bottom: 1.75rem;
    left: 5.5rem;
  }
  &::after {
    content: "ADVANTAGES";
    color: rgba(255, 255, 255, 0.05);
    font-size: 10rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 345px;
    background: #000;
    position: absolute;
    bottom: 3.5rem;
    left: 0rem;
    padding-right: $container-spacing-x;
  }
  .section-title {
    position: absolute;
    left: $container-spacing-x;
    bottom: 320px;
    z-index: 2;
    &-tc {
      position: absolute;
      right: 5rem;
      top: 25px;
    }
    @include media-breakpoint-down(xxl) {
      &-tc {
        right: 0rem;
        top: 12px;
      }
    }
  }
  .advatage-items {
    @include ul-no-style;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
  .advatage-item {
    width: 250px;
    height: 450px;
    border-radius: 1rem;
    border: 1px solid #fff;
    color: #fff;
    padding: $container-spacing-x-md;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: $font-size-lg;
    line-height: 1.4;
    position: relative;
    overflow: hidden;

    > * {
      position: relative;
    }
    &::before {
      content: "";
      border-color: transparent;
      bottom: 0;
      position: absolute;
      border-style: solid;
      transition: all 1s ease;
    }
    .advatage-focus {
      font-size: 3rem;
      font-weight: $font-weight-bold;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &.ux {
      background: url(../images/ux.jpg) center center/cover no-repeat;
      transform: translateY(1rem);
      &::before {
        left: 0;
        border-width: 0 675px 0 0;
        border-color: transparent transparent rgba(#ff0000, 0.4) transparent;
        @at-root #advantage
            .advatage-items.animated-section
            .advatage-item.ux::before {
          border-width: 0 675px 155px 0;
        }
      }
    }

    &.custom {
      background: url(../images/custom.jpg) -80px center/cover no-repeat;
      transform: translateY(3rem);
      &::before {
        right: 0;
        border-width: 0 0 0 845px;
        border-color: transparent transparent rgba(#ff0000, 0.5) transparent;
        @at-root #advantage
            .advatage-items.animated-section
            .advatage-item.custom::before {
          border-width: 0 0 155px 845px;
        }
      }
    }

    &.all {
      background: url(../images/all.svg) center center/cover no-repeat;
      &::before {
        right: 0;
        border-width: 0 0 0 845px;
        border-color: transparent transparent rgba(#ff0000, 0.6) transparent;
        @at-root #advantage
            .advatage-items.animated-section
            .advatage-item.all::before {
          border-width: 0 0 180px 845px;
        }
      }
    }

    &.deep {
      background: url(../images/deep.svg) -300px center/cover no-repeat;
      transform: translateY(1rem);
      &::before {
        left: 0;
        border-width: 0 675px 0 0;
        border-color: transparent transparent rgba(#ff0000, 0.75) transparent;
        @at-root #advantage
            .advatage-items.animated-section
            .advatage-item.deep::before {
          border-width: 0 675px 175px 0;
        }
      }
    }
  }
  @include media-breakpoint-down(xxxl) {
    flex-direction: column;
    justify-content: center;
    .section-title {
      position: relative;
      left: unset;
      bottom: unset;
      align-self: flex-start;
      .text-white {
        color: $primary !important;
      }
    }
    .advatage-items {
      margin-bottom: 3rem;
    }
  }
  @include media-breakpoint-down(xxl) {
    .advatage-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      width: 100%;
    }
    .advatage-item {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 2.5rem $container-spacing-x-lg;
    .advatage-item {
      font-size: 1rem;
      .advatage-focus {
        font-size: 1.5rem;
      }
    }
    &::after {
      padding-right: $container-spacing-x-lg;
    }
  }
  @include media-breakpoint-down(lg) {
    .advatage-items {
      margin-top: 2.5rem;
    }
    .advatage-item {
      height: 300px;
      &.deep {
        background-position: center center;
      }
    }
    &::after {
      font-size: 5rem;
      height: 50%;
      bottom: 0;
    }
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-md;
    &::after {
      padding-right: 0;
      content: "";
    }
    .section-title-tc {
      top: 6px;
    }
    .advatage-items {
      display: block;
      margin-top: $container-spacing-y-md;
      margin-bottom: 0;
    }
    .advatage-item {
      transform: translateY(0) !important;
      height: 250px;
      &:not(:last-child) {
        margin-bottom: $container-spacing-x-md;
      }
    }
  }
}
