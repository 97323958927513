#loading-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(ellipse, #525252, #252525);
  color: #757575;
  z-index: 1021;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  img {
    margin-bottom: -30px;
    position: relative;
    max-width: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: #252525;
    opacity: 0;
    animation-duration: 3s;
    animation-name: blinkblink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
}
@keyframes blinkblink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
