body {
  display: grid;
  grid-template-columns: 1fr 173px;
  letter-spacing: 0.8px;
  position: fixed;
  &.onload {
    position: initial;
  }
  @include media-breakpoint-down(lg) {
    display: block;
    padding-top: 56px;
  }
  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}
a {
  text-decoration: none;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 0;
}
.aside-nav {
  width: 100%;
  height: 100vh;
  grid-column: 2/3;
  grid-row: 1 / span 1;
  position: sticky;
  top: 0;
  background: url(../images/slider-bg.svg) center center / cover no-repeat;
  color: #fff;
  padding: 50px 1rem 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    color: #fff;
  }

  .en-name {
    font-size: $font-size-xs;
    writing-mode: tb-rl;
    font-weight: $font-weight-bold;
  }
}
.menu {
  @include ul-no-style;
  a {
    display: flex;
    align-items: center;
    opacity: 0.75;

    img {
      margin-right: 0.75rem;
    }
    &.arrive {
      opacity: 1;
    }
  }
  li:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  @include media-breakpoint-down(md) {
    li:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}
.contact-us {
  text-align: center;
  font-size: $font-size-xs;
  letter-spacing: 0.6px;
  > div {
    margin-top: 0.5rem;
  }
  @include media-breakpoint-down(md) {
    color: #fff;
    &:focus,
    &:hover {
      color: #fff;
    }
    img {
      width: 50px;
    }
  }
}
.main-content {
  grid-column: 1/2;
  grid-row: 1 / span 1;
}
.footer {
  background: $primary url(../images/footer-bg.svg) center right/cover no-repeat;
  color: #fff;
  padding: 3.5rem 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-sm;
  &-info {
    @include ul-no-style;
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 3.5rem 3rem;
  }
  @include media-breakpoint-down(md) {
    padding: $container-spacing-md;
    display: block;
    text-align: center;
    .footer-info {
      margin-bottom: 1rem;
    }
  }
}
nav.navbar {
  background: url(../images/business-card-bg.svg) left center/1209px 687px
    no-repeat no-repeat;
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    img {
      width: 35px;
      height: auto;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.75rem;
    }
  }
}
.offcanvas {
  width: 173px;
  background: url(../images/slider-bg.svg) top center/1000% no-repeat;
  align-items: center;
}
.offcanvas-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 35px;
    height: auto;
    margin-right: 1rem;
  }
}
.offcanvas-header {
  padding: $container-spacing-md;
  .btn-close {
    border-radius: 50%;
    background-color: #ffff;
    margin-right: 0;
  }
}
.offcanvas-body {
  padding: $container-spacing-md;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .en-name {
    font-size: 0.75rem;
    writing-mode: tb-rl;
    font-weight: $font-weight-bold;
  }
}
.navbar-nav {
  width: 100%;
  .nav-link {
    color: #fff !important;
    &:hover,
    &:focus {
      color: #fff !important;
    }
  }
}
